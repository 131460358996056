/* About.css */

.about-section {
  background: #004a7d;
  color: white;
  padding: 50px 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.about-content p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 80%;
}

.partners-logos {
  display: flex;
  gap: 20px;
  align-items: center;
}

.partner-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  background: white;
  padding: 10px;
}

.contacts-section {
  margin-top: 50px;
  text-align: center;
}

.contacts {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.contact-item i {
  font-size: 1.5rem;
  color: white;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 50%;
}

/* Responsive */
@media (max-width: 768px) {
  .contacts {
    flex-direction: column;
    gap: 20px;
  }

  .partners-logos {
    justify-content: center;
  }
}

.about-section {
  color: white;
}

.about-section h2,
.about-section p,
.about-section span,
.about-section a {
  color: white;
}

/* Style pour la section Blog */
.blog-content {
  margin-right: 0px;
}

.article-item {
  margin: 2px 0;
  padding: 2px;
  border-bottom: 1px solid #eee;
}

.article-link {
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  transition: color 0.3s ease;
}

.article-link:hover {
  color: #f8f9fa;
  text-decoration: underline;
}

.about-content .text-white {
  font-size: 14px; /* Ajuste la taille de la police à ton goût */
}

.partners-content {
  margin-left: 0px; /* Ajuste cette valeur selon le décalage souhaité */
}
